import { Alert, Flashbar, Link, Table, TextFilter } from '@amzn/awsui-components-react/polaris';
import React, { useEffect, useState } from 'react';
import { getReachoutTemplates } from '../../../api/api';
import { ReachoutTemplate, BrokerEngineType, Broker } from '../../../types';
import * as Arn from "@aws-sdk/util-arn-parser";

type Props = {
    broker: Broker
}

const ReachoutTemplates : React.FC<Props> = ({broker}) => {

    const [templates, setTemplates] = useState<ReachoutTemplate[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [filteringText, setFilteringText] = useState<string>("");

    function isDeprecated(template: ReachoutTemplate) {
        return template.title.toLowerCase().includes("[deprecated]");
    }

    function getAddEditTemplateLink(brokerEngineType: BrokerEngineType) {
        if (brokerEngineType === BrokerEngineType.RABBITMQ) {
            return 'https://sim.amazon.com/folders/AWS-AMQ-Rabbit%3A%20Tickets/da882782-0924-4c00-af14-cce2e57c563e';
        } else if (brokerEngineType === BrokerEngineType.ACTIVEMQ) {
            return "https://sim.amazon.com/folders/Amazon%20MQ/a684b037-cb02-4cec-ba83-60d863596891";
        } else {
            throw new Error("Unknown broker engine type: " + brokerEngineType);
        }
    }

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getReachoutTemplates(broker.summary.brokerEngineType).then(response => {
            setTemplates(response.reachoutTemplates.filter(template => !isDeprecated(template)));
        }).catch(error => {
            setError("Could not load reachouts.");
            console.error(error);
        }).finally(() => {
            setLoadingData(false);
        })
    }, []);

 
    function getBrokerReachoutDescription(description: string) {
        let brokerArn = Arn.parse(broker.brokerInfo.arn)

        const accountId = brokerArn.accountId;
        const brokerName = brokerArn.resource.split(":")[1]
        const regionNotAirportCode = brokerArn.region;

        let parametersMap = new Map([
            ["<ACCOUNT_ID>", accountId],
            ["<BROKER_NAME>", brokerName],
            ["<BROKER_ARN>", broker.brokerInfo.arn],
            ["<BROKER_ID>", broker.id],
            ["<REGION>", regionNotAirportCode]
        ]);

        parametersMap.forEach((value, key) => {
            description = description.replaceAll(new RegExp(key, 'g'), value);
        })

        return encodeURIComponent(description);
    }

    const simTemplateUrlPrefix = "https://issues.amazon.com/issues/create?template=";
    const addEditTemplateLink = getAddEditTemplateLink(broker.summary.brokerEngineType);

    return (
        <div className="brokerReachoutTemplateDiv" style={{display: 'flex', gap: 8, flexDirection: 'column'}}>
            <Alert
                visible={true}
                header="Note"
            >
                There might be fields that are not substituted (e.g START_TIME_UTC) that still need to be filled in.
                Please review the reachout for incomplete fields before sending it.
            </Alert>
            <div>
                <Link href={addEditTemplateLink} target='_blank' external={true}>Add or Edit reachout template</Link>
            </div>
            <div className="infoTableDiv">
                {error && <Flashbar items={
                                [{
                                    "type": "error",
                                    "dismissible": false,
                                    "content": error
                                }]
                            }></Flashbar>}
                <Table
                    items={templates.filter(t => t.title && t.title.toLowerCase().includes(filteringText.toLowerCase()))}
                    wrapLines={true}
                    loading={loadingData}
                    filter={<TextFilter
                        filteringPlaceholder="Search Reachout Template"
                        filteringText={filteringText}
                        onChange={(event) => {
                            setFilteringText(event.detail.filteringText);
                        }}
                    />}
                    columnDefinitions={[
                    {
                        id: "reachoutName",
                        header: "Reachout",
                        cell: (item: ReachoutTemplate) => <Link href={simTemplateUrlPrefix+item.templateId+"&description=" + getBrokerReachoutDescription(item.description)} target="_blank">{item.title}</Link>
                    }]}
                />
            </div>
        </div>
    );

}

export default ReachoutTemplates;