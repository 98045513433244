import React, { useEffect, useState } from 'react';
import {
    Button,
    Flashbar,
    Modal,
    StatusIndicator,
    Table
} from '@amzn/awsui-components-react/polaris';
import { isoFormat } from 'd3-time-format';
import { Broker, HOTWExecution, HOTWExecutionStatus } from '../../../types';
import { getHOTWExecutions } from '../../../api/api';
import { useCollection } from '@amzn/awsui-collection-hooks';

type Props = {
    broker: Broker,
}

const HOTWExecutions : React.FC<Props> = ({broker}) => {

    const [executions, setExecutions] = useState<HOTWExecution[]>([]);
    const [error, setError] = useState<string>("");
    const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
    const [modalHeader, setModalHeader] = useState<string>("");
    const [modalContent, setModalContent] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    
    const {
        items,
        collectionProps,
      } = useCollection(executions ?? [], {
        sorting: {
          defaultState: {
            sortingColumn: {
                sortingField: 'timestamp'
            },
            isDescending: true,
          },
        },
      });
      
    useEffect(() => {
        setError("");
        setLoading(true);
        getHOTWExecutions(broker.id, broker.snapshotId).then(response => {
            setExecutions(response.executions);
        }).catch(error => {
            console.error(error);
            setError("Could not load HOTW execution history");
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    function closeModal() {
        setModalIsVisible(false);
        setModalHeader("");
        setModalContent("");
    }

    function openModal(header: string, content: string) {
        setModalHeader(header);
        setModalIsVisible(true);
        setModalContent(content);
    }

    function getDetailsCell(item: any) {
        if (item.details === undefined || item.details.length === 0) {
            return "No HOTW Execution details found";
        }

        let executionDetailsModalContents = joinStringInList(item.details);
        let executionDetailsPreview;

        if (item.details.length > 2) {
            executionDetailsPreview = "Click to show the full execution details content...";
        } else {
            executionDetailsPreview = joinStringInList(item.details);
        }

        return <Button variant="link" onClick={() => {
            openModal("Details", executionDetailsModalContents);
        }}><p style={{textAlign: 'left'}}>{executionDetailsPreview}</p></Button>;
    }

    function joinStringInList(list: string[]) {
        let joinedString = "";
        list.map((value) => joinedString += value + "\n");
        return joinedString;
    }

    function isFailed(status: HOTWExecutionStatus) {
        return [HOTWExecutionStatus.FAILED,
                HOTWExecutionStatus.FAILED_INVOKER_ERROR,
                HOTWExecutionStatus.FAILED_AUTOMATION_ERROR,
                HOTWExecutionStatus.FAILED_MITIGATION_NO_ERROR,
                HOTWExecutionStatus.FAILED_SAFETY_CHECKS,
            ].includes(status);
    }

    function isSucceeded(status: HOTWExecutionStatus) {
        return [HOTWExecutionStatus.COMPLETED,
                HOTWExecutionStatus.SUCCESS_MITIGATED,
                HOTWExecutionStatus.SUCCESS_NO_ISSUE].includes(status);
    }

    function isInProgress(status: HOTWExecutionStatus) {
        return [HOTWExecutionStatus.IN_PROGRESS,
                HOTWExecutionStatus.EXECUTION_IN_PROGRESS].includes(status);
    }

    function renderHOTWStatus(status: HOTWExecutionStatus) {
        if (isFailed(status)) {
            return <StatusIndicator type='error'>{status}</StatusIndicator>;
        } else if (isSucceeded(status)) {
            return <StatusIndicator type='success'>{status}</StatusIndicator>;
        } else if (isInProgress(status)) {
            return <StatusIndicator type='in-progress'>{status}</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{status}</StatusIndicator>;
        }
    }

    let columnDefinitions = [
        {
            id: "timestamp",
            header: "Timestamp",
            sortingField: 'timestamp',
            cell: (item: HOTWExecution) => isoFormat(new Date(item.timestamp)),
        },
        {
            id: "sopName",
            header: "SOP Name",
            cell: (item: HOTWExecution)  => item.sopName
        },
        {
            id: "executionStatus",
            header: "Status",
            sortingField: 'executionStatus',
            cell: (item: HOTWExecution)  => renderHOTWStatus(item.executionStatus)
        },
        {
            id: "actionTaken",
            header: "Action Taken",
            cell: (item: HOTWExecution)  => '' + item.actionTaken
        },
        {
            id: "details",
            header: "Details",
            cell: (item: HOTWExecution)  =>  getDetailsCell(item)
        }
    ];

    return (
        <div>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
            {!error &&
                <>
                    <Table
                        {...collectionProps}
                        items={items}
                        columnDefinitions={columnDefinitions}
                        empty="No HOTW Executions found"
                        wrapLines={true}
                        loading={loading}
                    />
                    <Modal
                        size="max"
                        visible={modalIsVisible}
                        header={modalHeader}
                        onDismiss={() => {
                            closeModal();
                        }}
                        footer={
                            <span className="awsui-util-f-r">
                                <Button variant="primary" onClick={() => {
                                    closeModal();
                                }}>ok</Button>
                            </span>
                        }>
                        <span>{modalContent}</span>
                    </Modal>
                </>
            }
        </div>
    );
}

export default HOTWExecutions;
