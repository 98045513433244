import React, { memo, useState } from 'react';

import CloudWatchDashboard from './CloudWatchDashboard';
import { Checkbox } from '@amzn/awsui-components-react/polaris';
import { getRabbitInstanceMetrics } from './metrics/rabbitInstanceMetrics';
import { getActiveInstanceMetrics } from './metrics/activeInstanceMetrics';
import { Broker, BrokerEngineType, Ec2Instance } from '../../../types';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[],
}

const EC2MetricsTab : React.FC<Props> = ({broker, brokerInstances}) => {


    const [selectedInstances, setSelectedInstances] = useState<string[]>(brokerInstances.map(bi => bi.instanceId));

    let allInstances : string[] = [];
    for (let i = 0; i < brokerInstances.length; i++) {
        allInstances.push(brokerInstances[i].instanceId);
    }
    
    for (let i = 0; i < broker.instanceHistory.length; i++) {
        if (allInstances.indexOf(broker.instanceHistory[i].instanceId) < 0) {
            allInstances.push(broker.instanceHistory[i].instanceId);
        }
    }
    
    allInstances = allInstances.reverse();

    const rowLength = selectedInstances.length;

    let instanceMetrics: any[][] = [];
 
    for (let i = 0; i < allInstances.length; i++) {
        if (selectedInstances.indexOf(allInstances[i]) >= 0) {
            if (broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
                let qualifiedBrokerId = brokerInstances.find(bi => bi.instanceId === allInstances[i])?.qualifiedBrokerId;
                if (!qualifiedBrokerId) {
                    qualifiedBrokerId = "not-found"; // there is no easy way to find qualifiedBrokerId for terminated instance, so we just show empty graph
                }
                instanceMetrics.push(getActiveInstanceMetrics(broker, allInstances[i], qualifiedBrokerId, brokerInstances));
            } else if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
                instanceMetrics.push(getRabbitInstanceMetrics(broker, allInstances[i], brokerInstances));
            } else {
                throw new Error("Unknown engine type: " + broker.summary.brokerEngineType);
            }
        }
    }

    let cells = [];
    let metrics_count = instanceMetrics.length > 0 ? instanceMetrics[0].length : 0;
    let cell_count = metrics_count * selectedInstances.length;
    for (let cellIdx = 0; cellIdx < cell_count; cellIdx++) {
        let instanceIdx = cellIdx % rowLength;
        let metricIdx = Math.floor(cellIdx / rowLength);
        cells.push(instanceMetrics[instanceIdx][metricIdx]);
    }

    function isCurrentInstance(instanceId: string) {
        return brokerInstances.find(bi => bi.instanceId === instanceId) !== undefined;
    }

    return (
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: 8, alignItems: 'center', alignContent: 'center', padding: 8}}>
                {allInstances.map(instanceId => {
                    return (
                        <div key={`checkbox-${instanceId}`}>
                            <Checkbox
                                className={isCurrentInstance(instanceId) ? 'currentInstanceCheckbox' : 'terminatedInstanceCheckbox'}
                                checked={selectedInstances.indexOf(instanceId) >= 0}
                                onChange={(event: any) => {
                                    if (event.detail.checked) {
                                        selectedInstances.push(instanceId);
                                    } else {
                                        let idx = selectedInstances.indexOf(instanceId);
                                        if (idx >= 0) {
                                            selectedInstances.splice(idx, 1);
                                        }
                                    }
                                    setSelectedInstances([...selectedInstances]);
                                }}
                            >{instanceId}</Checkbox>
                        </div>
                    )
                })}
            </div>
            {cells.length > 0 &&
                <CloudWatchDashboard
                    broker={broker}
                    layoutType='automatic'
                    widgetsPerRow={rowLength}
                    serviceAccountId={broker.summary.serviceAccountId}
                    widgets={cells}
                /> 
            }
        </div>
    )
}

export default memo(EC2MetricsTab);