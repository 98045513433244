
import React, {useEffect, useState} from 'react';
import {
    Button,
    ColumnLayout,
    Container,
    Flashbar,
    Form,
    FormField,
    Input, Select,
    Textarea,
} from '@amzn/awsui-components-react/polaris';
import { getRabbitBrokerConfigOverride, updateRabbitBrokerConfigOverride } from '../../../api/api';
import { Broker } from '../../../types';

type Props = {
    broker: Broker,
}

type Selection = {
    id: string,
    label: string,
}

const configSelections = [
    {
        "label": "Heartbeat",
        "id": "heartbeat"
    },
    {
        "label": "Max Message Size",
        "id": "max_message_size"
    },
    {
        "label": "Connection Max",
        "id": "connection_max"
    },
    {
        "label": "Channel Max",
        "id": "channel_max"
    }
]

function isNumber(value?: string | number): boolean {
    return ((value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString())));
}

const ConfigOverride : React.FC<Props> = ({broker}) => {

    const [brokerConfigOverrideData, setConfigOverrideData] = useState<string>("loading...");
    const [loading, setLoading] = useState<boolean>(false);
    const [updateValue, setUpdateValue] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<Selection | null>(null);
    const [flashbarItems, setFlashbarItems] = useState<any>([]);

    useEffect(() => {
        setLoading(true);
        getRabbitBrokerConfigOverride(broker.id).then(response => {
            if (response.config === null) {
                setConfigOverrideData("No Configuration Override");
            } else {
                setConfigOverrideData(response.config);
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id])

    return (<div>
        <div>
            <Container
                header="RabbitMQ Broker Configuration Override"
            >
                <div style={{marginBottom: 8}}>
                    <div><h3>Current configuration overrides</h3></div>
                    <div>
                        <Textarea
                            disabled={true}
                            readOnly={true}
                            rows={5}
                            value={brokerConfigOverrideData}
                        />
                    </div>
                </div>
                <div>
                    <Container header="Update configuration overrides">
                        <ColumnLayout>
                            <div data-awsui-column-layout-root="true">
                                {/* <Select
                                    options={configSelections}
                                    placeholder="Please Select Config Name"
                                    selectedLabel="Selected"
                                    onChange={
                                        event => {
                                            setSelectedOption(event.detail.selectedOption);
                                        }
                                }></Select> */}
                                <Flashbar items={flashbarItems}></Flashbar>
                                {selectedOption === null ? <></> :
                                    <FormField label={selectedOption.label}>
                                        <Input
                                            value={updateValue}
                                            disabled={false}
                                            type="number"
                                            inputMode="numeric"
                                            autoComplete={true}
                                            onChange={event => {
                                                if (isNumber(event.detail.value)) {
                                                    setUpdateValue(event.detail.value);
                                                }
                                            }}
                                            onKeyDown={event => {
                                                if (!/[0-9]/.test(event.detail.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <Button
                                            disabled={false}
                                            variant="primary"
                                            onClick={
                                                () => {
                                                    if (isNumber(updateValue)) {
                                                        updateRabbitBrokerConfigOverride(broker.id, {
                                                            configName: selectedOption.id,
                                                            configValue: updateValue
                                                        })
                                                            .then(response => {
                                                                if (response.config === null) {
                                                                    setConfigOverrideData("No Configuration Override");
                                                                } else {
                                                                    setConfigOverrideData(response.config);
                                                                }
                                                                setFlashbarItems([{
                                                                    "type": "success",
                                                                    "content": `Config override request ${selectedOption.label} with value ${updateValue} has been sent`,
                                                                    "dismissible": true
                                                                }])
                                                            }).catch(error => {
                                                            setFlashbarItems([{
                                                                "header": "[ERROR] " + error.code,
                                                                "type": "error",
                                                                "content": error.message,
                                                                "dismissible": true
                                                            }])
                                                        }).finally(() => {
                                                            setLoading(false);
                                                        })
                                                    } else {
                                                        setFlashbarItems([{
                                                            "header": "[ERROR] Value is not a number",
                                                            "type": "error",
                                                            "content": `Config override request ${selectedOption.label} with value ${updateValue} has failed`,
                                                            "dismissible": true
                                                        }])
                                                    }
                                                }
                                            }
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            disabled={true}
                                            variant="normal"
                                        >
                                            Remove
                                        </Button>
                                    </FormField>
                                }
                            </div>
                        </ColumnLayout>
                    </Container>
                </div>
            </Container>
        </div>
    </div>
)   ;
}

export default ConfigOverride;
