import React, { useEffect, useState } from 'react';
import { Broker, CustomerMetricName } from '../../../types';

import { getCustomerMetricNames } from '../../../api/api';
import CustomerMetricsTable from './CustomerMetricsTable';
import { Table } from '@amzn/awsui-components-react';
type Props = {
    broker: Broker,
}

const CustomerMetricsPage : React.FC<Props> = ({broker}) => {

    const [destinationNames, setDestinationNames] = useState<CustomerMetricName[]>([]);
    const [destinationType, setDestinationType] = useState<any>(null);
    const [destinationName, setDestinationName] = useState<any>(null);
    const [enqueueCount, setEnqueueCount] = useState<any[]>([]);
    const [dequeueCount, setDequeueCount] = useState<any[]>([]);
    const [producerCount, setProducerCount] = useState<any[]>([]);
    const [consumerCount, setConsumerCount] = useState<any[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingNamesData, setLoadingNamesData] = useState<boolean>(false);

    useEffect(() => {
        setLoadingNamesData(true);
        getCustomerMetricNames(broker.id, broker.snapshotId).then(response => {
            setDestinationNames(response.customerMetricNames);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingNamesData(false);
        })
    }, []);

    // useEffect(() => {
        // setLoadingData(true);
        // getCustomerMetricData(broker.id, snapshotId, destinationName, destinationType).then(response => {
        //     // TODO this feature is not working.
        //     // this.setState({
        //         //             enqueueCount: this.getData(responseData.EnqueueCount),
        //         //             dequeueCount: this.getData(responseData.DequeueCount),
        //         //             consumerCount: this.getData(responseData.ConsumerCount),
        //         //             producerCount: this.getData(responseData.ProducerCount),
        //         //             loadingData: false
        //         //         })
        // }).catch(error => {
        //     console.error(error);
        // }).finally(() => {
        //     setLoadingNamesData(false);
        // })
    // }, [destinationType, destinationName])

    function store(type: string, name: string) {
        setDestinationType(type);
        setDestinationName(name);
    }

    function getArcturusLinks(arcturusLinks: any[]) {
        return arcturusLinks.map(function(link) {
            return <div key={link["name"]}><a href={link["link"]} target="_blank">{link["name"]}</a><br></br></div>;
        })
    }

    let columnDefinitions = [
        {
          id: "destinationType",
          header: "Destination Type",
          cell: (item: any) => item.type
        },
        {
          id: "destinationName",
          header: "Destination Name",
          cell: (item: any) => <a style={{cursor:"pointer"}} onClick={() => store(item.type, item.name)}>{item.name}</a>
        },
        {
          id: "arcturusLink",
          header: "Impersonator Link",
          cell: (item: any) => getArcturusLinks(item.links)
        }
    ];

    let sortableColumns = [
        {
            id: "destinationType",
            field: "type"
        },
        {
            id: "destinationName",
            field: "name"
        }
    ];


    return (
        <div>
            <div>
                <Table
                    items={destinationNames}
                    columnDefinitions={columnDefinitions}
                    empty="No Data found"
                    loading={loadingNamesData}
                />
                {destinationName !== null &&
                    <CustomerMetricsTable destinationType={destinationType} destinationName={destinationName} loadingData={loadingData}
                    enqueueCount={enqueueCount} dequeueCount={dequeueCount} consumerCount={consumerCount} producerCount={producerCount} />
                }
            </div>
        </div>
    );
}

export default CustomerMetricsPage;
