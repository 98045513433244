import { Broker, BrokerEngineType, BrokerEnvironment, BrokerMemoryBreakdown, BrokerTags, CloudwatchDashboardLink,
     ClusterStatus, CustomerMetricNamesList, EC2InstanceList, EventHistoryEntryList, HOTWExecutionsList, LogEntryList, LogStreamList, QualifiedFailoverEventList, RabbitBrokerConfigOverride, RabbitBrokerPolicies, RabbitBrokerQueues, ReachoutTemplateList, SimIssueList, SimMarkdown, WorkflowList } from "../types";
import { isLocalhost } from "../utils";
import { getApiClient } from "./client";

function getApiEndpoint() {
    if (isLocalhost()) {
        return `https://api.opsconsole.${process.env.REACT_APP_USER}.people.aws.dev`;
    } else {
        return `https://api.${window.location.hostname}`
    }
}

const API_ENDPOINT = getApiEndpoint();

async function fetch(endpoint: string): Promise<any> {
    try {
        let apiClient = await getApiClient();
        let response = await apiClient.client.get(endpoint);
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

async function put(endpoint: string, data: any): Promise<any> {
    try {
        let apiClient = await getApiClient();
        let response = await apiClient.client.put(endpoint, data);
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

async function post(endpoint: string, data: any): Promise<any> {
    try {
        let apiClient = await getApiClient();
        let response = await apiClient.client.post(endpoint, data);
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

async function _delete(endpoint: string): Promise<any> {
    try {
        let apiClient = await getApiClient();
        let response = await apiClient.client.delete(endpoint);
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export function getBrokerById(brokerId: string): Promise<Broker> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}`);
}

export function getBrokerInstances(brokerId: string, snapshotId: string): Promise<EC2InstanceList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/instances?snapshotId=${snapshotId}`);
}

export function getLogStreams(brokerId: string, snapshotId: string):  Promise<LogStreamList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/log-streams?snapshotId=${snapshotId}`);
}

export function getLogs(brokerId: string, snapshotId: string, logStreamId: string): Promise<LogEntryList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/logs?snapshotId=${snapshotId}`);
}

export function getSimIssues(brokerId: string, snapshotId: string): Promise<SimIssueList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/sim-issues?snapshotId=${snapshotId}`);
}

export function getBrokerWorkflows(brokerId: string, snapshotId: string): Promise<WorkflowList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/workflows?snapshotId=${snapshotId}`);
}

export function getReachoutTemplates(engineType: BrokerEngineType): Promise<ReachoutTemplateList> {
    return fetch(`${API_ENDPOINT}/reachout-templates/${engineType}`);
}

export function getCustomerMetricNames(brokerId: string, snapshotId: string): Promise<CustomerMetricNamesList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/customer-metric-names?snapshotId=${snapshotId}`);
}

export function getCloudwatchDashboardLink(brokerId: string, snapshotId: string): Promise<CloudwatchDashboardLink> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/cloudwatch-dashboard-link?snapshotId=${snapshotId}`);
}

export function getEvents(brokerId: string, snapshotId: string): Promise<EventHistoryEntryList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/events`);
}

export function getSimMarkdown(brokerId: string, snapshotId: string): Promise<SimMarkdown> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/sim-markdown?snapshotId=${snapshotId}`);
}

export function getHOTWExecutions(brokerId: string, snapshotId: string): Promise<HOTWExecutionsList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/hotw-executions?snapshotId=${snapshotId}`);
}

export function getFailoverHistory(brokerId: string, snapshotId: string): Promise<QualifiedFailoverEventList> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/failover-history?snapshotId=${snapshotId}`);
}

export function getRabbitBrokerMemoryBreakdown(brokerId: string, snapshotId: string): Promise<BrokerMemoryBreakdown> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/memory-breakdown?snapshotId=${snapshotId}`);
}

export function getRabbitBrokerEnvironment(brokerId: string, snapshotId: string): Promise<BrokerEnvironment> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/broker-environment?snapshotId=${snapshotId}`);
}

export function getRabbitClusterStatus(brokerId: string): Promise<ClusterStatus> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/cluster-status`);
}

export function getRabbitBrokerQueues(brokerId: string, instanceId: string | undefined): Promise<RabbitBrokerQueues> {
    if (instanceId === undefined) {
        return fetch(`${API_ENDPOINT}/broker/${brokerId}/broker-queues`);
    } else {
        return fetch(`${API_ENDPOINT}/broker/${brokerId}/broker-queues?instanceId=${instanceId}`);
    }
}

export function getRabbitBrokerConfigOverride(brokerId: string): Promise<RabbitBrokerConfigOverride> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/config-override`);
}

export function updateRabbitBrokerConfigOverride(brokerId: string, data: {
    configName: string;
    configValue: string;
}): Promise<RabbitBrokerConfigOverride> {
    return put(`${API_ENDPOINT}/broker/${brokerId}/config-override`, data);
}

export function getRabbitBrokerPolicies(brokerId: string): Promise<RabbitBrokerPolicies> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/broker-policies`);
}

export function addRabbitBrokerTag(brokerId: string, tag: string): Promise<any> {
    return post(`${API_ENDPOINT}/broker/${brokerId}/tags/${tag}`, {});
}

export function getRabbitBrokerTags(brokerId: string): Promise<BrokerTags> {
    return fetch(`${API_ENDPOINT}/broker/${brokerId}/tags`);
}

export function deleteRabbitBrokerTag(brokerId: string, tag: string): Promise<any> {
    return _delete(`${API_ENDPOINT}/broker/${brokerId}/tags/${tag}`);
}