import { Broker, Ec2Instance, VolumeType } from "../../../../types";
import { widget } from "../utils";
import * as Arn from "@aws-sdk/util-arn-parser";

export function getRabbitInstanceMetrics(broker: Broker, instanceId: string, brokerInstances: Ec2Instance[]): any[] {
    let metrics : any[] = [];
    let region = Arn.parse(broker.brokerInfo.arn).region;
    let rootVolumeId = brokerInstances.find(bi => bi.instanceId == instanceId)?.volumes.find(v => v.volumeType === VolumeType.ROOT_VOLUME)?.volumeId;
    let dataVolumeId = brokerInstances.find(bi => bi.instanceId == instanceId)?.volumes.find(v => v.volumeType === VolumeType.DATA_VOLUME)?.volumeId;

    metrics.push(widget(
        `${instanceId} - ActiveNodeCount, RabbitMqContainerAge`,
        region,
        [
            activeNodeCountMetric(broker, instanceId),
            rabbitMqContainerAgeMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceId} - RabbitMqMemLimit, RabbitMqMemUsed`,
        region,
        [
            rabbitMqMemLimitMetric(broker, instanceId),
            rabbitMqMemUsedMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceId} - RabbitMQDiskFree, RabbitMQDiskFreeLimit`,
        region,
        [
            rabbitMqDiskFreeMetric(broker, instanceId),
            rabbitMqDiskLimitMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EC2 CPU Utilization`,
        region,
        [
            cpuUtilizationMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EC2 Status Check`,
        region,
        [
            statusCheckFailedSystemMetric(broker, instanceId),
            statusCheckFailedInstanceMetric(broker, instanceId),
            statusCheckFailedMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EC2 Network`,
        region,
        [
            networkInMetric(broker, instanceId),
            networkOutMetric(broker, instanceId),
            networkPacketsInMetric(broker, instanceId),
            networkPacketsOutMetric(broker, instanceId)
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EBS Latency - Root Volume`,
        region,
        [
            volumeTotalReadTimeMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeTotalWriteTimeMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeQueueLengthMetric(broker, rootVolumeId !== undefined ? rootVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EBS Throughput - Root Volume`,
        region,
        [
            volumeReadBytesMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeWriteBytesMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeReadOpsMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeWriteOpsMetric(broker, rootVolumeId !== undefined ? rootVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EBS Burst Balance - Root Volume`,
        region,
        [
            volumeBurstBalanceMetricc(broker, rootVolumeId !== undefined ? rootVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EBS Latency - Data Volume`,
        region,
        [
            volumeTotalReadTimeMetric(broker, dataVolumeId !== undefined ? dataVolumeId : ""),
            volumeTotalWriteTimeMetric(broker, dataVolumeId !== undefined ? dataVolumeId : ""),
            volumeQueueLengthMetric(broker, dataVolumeId !== undefined ? dataVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EBS Throughput - Data Volume`,
        region,
        [
            volumeReadBytesMetric(broker, dataVolumeId !== undefined ? dataVolumeId : ""),
            volumeWriteBytesMetric(broker, dataVolumeId !== undefined ? dataVolumeId : ""),
            volumeReadOpsMetric(broker, dataVolumeId !== undefined ? dataVolumeId : ""),
            volumeWriteOpsMetric(broker, dataVolumeId !== undefined ? dataVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceId} - EBS Burst Balance - Data Volume`,
        region,
        [
            volumeBurstBalanceMetricc(broker, dataVolumeId !== undefined ? dataVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceId} - RaftIndexDiff`,
        region,
        [
            raftIndexDiffMaxMetric(broker, instanceId),
            raftIndexDiffAvgMetric(broker, instanceId)
        ]
    ))

    metrics.push(widget(
        `${instanceId} - QuorumQueueSyncStuckMemberCount`,
        region,
        [
            quorumQueueSyncStuckMemberCountMetric(broker, instanceId)
        ]
    ))

    return metrics;
}



export function activeNodeCountMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "ActiveNodeCount",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ];
}

export function rabbitMqContainerAgeMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RabbitMqContainerAge",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function rabbitMqMemLimitMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RabbitMQMemLimit",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function rabbitMqMemUsedMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RabbitMQMemUsed",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function rabbitMqDiskFreeMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RabbitMQDiskFree",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function rabbitMqDiskLimitMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RabbitMQDiskFreeLimit",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}




export function cpuUtilizationMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "CPUUtilization",
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function statusCheckFailedSystemMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "StatusCheckFailed_System",
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function statusCheckFailedInstanceMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "StatusCheckFailed_Instance",
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function statusCheckFailedMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "StatusCheckFailed",
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}



export function networkInMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkIn",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function networkOutMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkOut",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function networkPacketsInMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkPacketsIn",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function networkPacketsOutMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkPacketsOut",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function volumeTotalReadTimeMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeTotalReadTime",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeTotalWriteTimeMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeTotalWriteTime",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeQueueLengthMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeQueueLength",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function volumeReadBytesMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeReadBytes",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeWriteBytesMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeWriteBytes",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeReadOpsMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeReadOps",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function volumeWriteOpsMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeWriteOps",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function volumeBurstBalanceMetricc(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "BurstBalance",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function raftIndexDiffMaxMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RaftIndexDiff",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function raftIndexDiffAvgMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RaftIndexDiff",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function quorumQueueSyncStuckMemberCountMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "QuorumQueueSyncStuckMemberCount",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}
