import React, { useState } from 'react';
import { Broker, BrokerEngineType, BrokerSummary, DRMode } from '../../types';
import { ColumnLayout, Container, Header, Link, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { renderElement, renderField, renderLink } from './tableUtils';
import { buildHostForDomainAndRegion, getDomainFromHost } from '../../utils';
import BrokerTagsComponent from './BrokerTagsComponent';

type Props = {
    broker: Broker,
}

function renderBrokerName(brokerSummary: BrokerSummary): React.ReactElement {
    if (brokerSummary.brokerState === 'AVAILABLE') {
        return <span>{brokerSummary.name} <StatusIndicator type='success'>{brokerSummary.brokerState}</StatusIndicator></span>
    } else if (brokerSummary.brokerState.indexOf('FAILED') >= 0) {
        return <span>{brokerSummary.name} <StatusIndicator type='error'>{brokerSummary.brokerState}</StatusIndicator></span>
    } else {
        return <span>{brokerSummary.name} <StatusIndicator type='info'>{brokerSummary.brokerState}</StatusIndicator></span>
    }
}

function renderCRDRSection(brokerSummary: BrokerSummary) : React.ReactNode {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>Running: {brokerSummary.drMode}</span>
            <span>Pending: {brokerSummary.pendingDrMode}</span>
            {brokerSummary.replicationRole &&
                <span>Replication Role: {brokerSummary.replicationRole}</span>
            }
            {brokerSummary.drCounterPart &&
                <Link external={true} target='_blank' href={`https://${buildHostForDomainAndRegion(getDomainFromHost(), brokerSummary.drCounterPart.region)}/broker/${brokerSummary.drCounterPart.brokerId}`}>Counterpart</Link>
            }
        </div>
    )
}

const BrokerSummaryTable : React.FC<Props> = ({broker}) => {

    const [brokerTags, setBrokerTags] = useState<string[]>([]);
    const brokerSummary = broker.summary;

    let content : React.ReactElement[] = [];
    content.push(renderElement("Name", renderBrokerName(brokerSummary), brokerSummary.name));
    content.push(renderLink("Account", brokerSummary.accountId, brokerSummary.accountLink, "Account Link"));
    content.push(renderField("Host Instance Type", brokerSummary.hostInstanceType));
    content.push(renderField("Broker Engine Type", brokerSummary.brokerEngineType));
    content.push(renderField("Deployment Mode", brokerSummary.deploymentMode));
    if (brokerSummary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(<BrokerTagsComponent brokerId={broker.id} />);
    }
    if (brokerSummary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
        content.push(renderField("Broker Instance Internal Tags",
            brokerSummary.brokerInstanceInternalTags.length > 0 ? brokerSummary.brokerInstanceInternalTags.join(", ") : "None"));
    }
    content.push(renderField("Quarantine States", brokerSummary.quarantineStates));

    if (brokerSummary.drMode !== DRMode.NONE) {
        content.push(renderElement("DR Mode", renderCRDRSection(brokerSummary), ""))
    }

    return (
        <Container header={
            <Header variant="h2">
                Broker Summary
            </Header>
        }>
            <ColumnLayout columns={4} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}

export default BrokerSummaryTable;