import { Button, Link, Modal, Table, TextFilter } from '@amzn/awsui-components-react';
import { getLogs } from '../../../api/api';
import { Broker, LogStream } from '../../../types';
import './logsTable.css';
import React, { useState } from 'react';
import LogDetailsTable from './logDetailsTable';

type Props = {
    broker: Broker,
    logStreamNames: LogStream[],
    loadingStreamNames: boolean
}

const LogsTable : React.FC<Props> = ({broker, logStreamNames, loadingStreamNames}) =>  {

    const [logs, setLogs] = useState<any[]>([]);
    const [loadingLogData, setLoadingLogData] = useState<boolean>(false);
    const [selectedLogStream, setSelectedLogStream] = useState<LogStream | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [filteringText, setFilteringText] = useState<string>("");
    const [error, setError] = useState<string>("");

    function loadLogs(logStream: LogStream) {
        setLoadingLogData(true);
        setSelectedLogStream(logStream);
        setModalOpen(true);
        setError("");
        getLogs(broker.id, broker.snapshotId, logStream.id).then(response => {
            setLogs(response.logs);
        }).catch(error => {
            console.error(error);
            setError("Could not load logs")
        }).finally(() => {
            setLoadingLogData(false);
        })
    }

    let columnDefinitions = [
        {
            id: "lastEventTimestamp",
            header: "Last Event Timestamp",
            cell: (item: LogStream) => <Link onClick={() => loadLogs(item)}>{item.latestTimestamp}</Link>,
            field: "latestTimestamp"
        },
        {
            id: "logGroup",
            header: "Log Group",
            cell: (item: LogStream) => <Link onClick={() => loadLogs(item)}>{item.logGroupName}</Link>,
            field: "logGroupName"
        },
        {
            id: "logStream",
            header: "Log Stream",
            cell: (item: LogStream) => <Link  onClick={() => loadLogs(item)}>{item.title}</Link>,
            field: "title"
        },
        {
            id: "instanceId",
            header: "Instance ID",
            cell: (item: LogStream) => <Link onClick={() => loadLogs(item)}>{item.instanceId}</Link>,
            field: "instanceId"
        },
        {
            id: "isengardLink",
            header: "Cloud Watch Logs",
            cell: (item: LogStream) => <Link  href={item.isenLink} target="_blank">Isengard Link</Link>,
        }
    ];

    return (
        <>
            <Table 
                items={logStreamNames.filter(i => i.title && i.title.toLowerCase().includes(filteringText.toLowerCase()))}
                filter={
                    <TextFilter
                        filteringPlaceholder="Search log group"
                        filteringText={filteringText}
                        onChange={(event) => {
                        setFilteringText(event.detail.filteringText);
                        }}
                    />
                    }
                columnDefinitions={columnDefinitions} 
                loading={loadingStreamNames}
                sortingDescending={true} 
            />
            {modalOpen &&
                 <Modal
                    size="large"
                    visible={modalOpen}
                    header={selectedLogStream?.logStreamName}
                    onDismiss={() => {
                        setModalOpen(false);
                    }}
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="primary" onClick={() => {
                                setModalOpen(false);
                            }}>Ok</Button>
                        </span>
                    }>
                         <LogDetailsTable
                            logs={logs}
                            error={error}
                            loadingData={loadingLogData}
                        />  
                </Modal>
            }
        </>
    );

}

export default LogsTable;
