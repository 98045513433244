import React from 'react';
import BrokerAlarmsTable from './brokerAlarmsTable';
import BrokerInfoTable from './brokerInfoTable';
import BrokerInstanceInfoTable from './brokerInstanceInfoTable';
import BrokerMaintenanceInfoTable from './brokerMaintenanceInfoTable';
import InstanceHistoryTable from '../../Components/instanceHistoryTable';
import { Broker, Ec2Instance } from '../../../types';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[] | undefined,
}

const BrokerDetails : React.FC<Props> = ({broker, brokerInstances}) => {

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <div key="broker-alarms">
                <BrokerAlarmsTable
                    brokerAlarms={broker.brokerInfo.brokerAlarms}
                />
            </div>
            <div key="broker-info">
                <BrokerInfoTable
                    broker={broker}
                    brokerInstances={brokerInstances}
                />
            </div>
            <div key="broker-instance-info">
                <BrokerInstanceInfoTable
                    broker={broker}
                    brokerInstances={brokerInstances}
                />
            </div>
            <div key="broker-maintenance-info">
                <BrokerMaintenanceInfoTable
                    broker={broker}
                    brokerMaintenanceInfo={broker.maintenanceInfo}
                />
            </div>
            {broker.instanceHistory.length > 0 &&
                <div key="instance-history">
                    <InstanceHistoryTable
                        instanceHistory={broker.instanceHistory}
                    />
                </div>
            }
        </div>
    )

}

export default BrokerDetails;