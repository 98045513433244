import { Broker } from '../types';
import semver from "semver";

const FEATURE_FLAGS = {
    CONFIG_OVERRIDE: false
}

export function isConfigOverrideSupported(broker: Broker) {
    if (!FEATURE_FLAGS.CONFIG_OVERRIDE) {
        return false;
    }
    const engine = broker.brokerInfo.engine;
    const regex = /\(([^)]+)\)/;
    const match = engine.match(regex);
    if (match && match.length >= 2) {
        const rabbitVersion = match[1]
        if (semver.satisfies(rabbitVersion, ">= 3.12")) {
            return true;
        }
    }
    return false;
}