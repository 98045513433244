import { AppLayout, Button, ContentLayout, Input, Select, Spinner } from '@amzn/awsui-components-react/polaris';
import React, { useEffect, useState } from 'react';
import { ALL_DOMAINS, Config, Domain, Region } from '../types';
import { buildHostForDomainAndRegion, generateRegionsOptions, getDomainFromHost, getRegionFromHost } from '../utils';
import { InvalidIdentityTokenException } from '@aws-sdk/client-sts';
import { getApiClient } from '../api/client';
import { useNavigate } from 'react-router';


function getDomains(username: string | undefined): Domain[] {
    if (username === undefined) {
        return ALL_DOMAINS;
    }
    let domains = [...ALL_DOMAINS];
    domains.push({
        "label": `dev-${username}`,
        "id": `dev-${username}`,
    });
    return domains;
}

type Props = {
    config: Config,
    regions: Region[]
}

const MainPage : React.FC<Props> = ({config, regions}) => {

    const defaultRegion = getRegionFromHost();
    const navigate = useNavigate();
    const [selectedBrokerDomain, setSelectedBrokerDomain] = useState<Domain | undefined>(getDomains(config.username).find(d => d.id === getDomainFromHost()));
    const domains = getDomains(config.username);
    const [brokerId, setBrokerId] = useState<string>("");
    const [selectedBrokerRegion, setSelectedBrokerRegion] = useState<Region | undefined>(regions.find(r => r.regionCode.toLowerCase() === defaultRegion.toLowerCase()));

    useEffect(() => {
        getApiClient().then(api => {
            setSelectedBrokerDomain(getDomains(api.user).find(d => d.id === getDomainFromHost()));
        }).catch(error => {
            console.error(error);
            if (error instanceof InvalidIdentityTokenException) {
                // this sometimes happens affter Midway token expires and user is redirected to Midway login screen.
                console.log("Invalid token, redirecting to home page.");   
                window.location.reload();
                return;
            }
        })
    }, [])

    const isLoading = 
            config === undefined
            || regions === undefined 
            || selectedBrokerRegion === undefined
            || selectedBrokerDomain === undefined;
    

    function searchBroker(brokerId: string, selectedDomain: string, selectedRegionNotAirport: string) {
        let newHost = buildHostForDomainAndRegion(selectedDomain, selectedRegionNotAirport);
        if (window.location.host !== newHost) {
            window.location.href = `https://${newHost}/broker/${brokerId}`
        } else {
            navigate(`/broker/${brokerId}`);
        }
    }
    
    return (
        <AppLayout
            toolsHide={true}
            navigationHide={true}
            content={
                <ContentLayout>
                        <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 8,
                                alignContent: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                height: 'calc(100vh - 150px)',
                        }}>
                            {isLoading && <Spinner size='large' />}
                            {!isLoading &&
                                <>
                                    <Select
                                        options={domains.map(d => ({ value: d.id, label: d.label }))}
                                        selectedOption={{value: selectedBrokerDomain.id, label: selectedBrokerDomain.label}}
                                        onChange={({detail}) => {
                                            setSelectedBrokerDomain(domains.find(d => d.id === detail.selectedOption.value));
                                        }}
                                    />
                                    <Select
                                        options={generateRegionsOptions(regions)}
                                        selectedOption={{value: selectedBrokerRegion.regionCode, label: selectedBrokerRegion.airportCode + ' (' + selectedBrokerRegion.regionCode + ')'}} onChange={event => {
                                            setSelectedBrokerRegion(regions.find(r => r.airportCode.toLowerCase() === event.detail.selectedOption.value));
                                        }}
                                    />
                                    <Input type="search" autoFocus={true} value={brokerId} placeholder='Search broker ID/ARN' onKeyDown={(event) => {
                                        if (event.detail.key === 'Enter') {
                                            searchBroker(brokerId, selectedBrokerDomain.id, selectedBrokerRegion.regionCode);
                                        }
                                    }} onChange={(event) => {
                                        setBrokerId(event.detail.value);
                                    }} autoComplete={false}/>
                                    <Button
                                        variant="primary"
                                        disabled={!brokerId}
                                        href={`https://${buildHostForDomainAndRegion("prod", "ap-southeast-2")}/broker/${brokerId}`}
                                    >
                                        Describe Broker
                                    </Button>
                                </>
                            }
                        </div>
                </ContentLayout>
            }
        />
    )
}

export default MainPage;