import React, { useEffect, useRef, useState } from 'react';
import { CloudWatchWidget } from './types';
import { Spinner } from '@amzn/awsui-components-react/polaris';
import * as Arn from "@aws-sdk/util-arn-parser";
import { Broker } from '../../../types';
import Dashboard from '../../../CloudwatchDashboard/Dashboard';
import { Mode } from '@amzn/awsui-global-styles';

type Props = {
    broker: Broker,
    widgetsPerRow?: number,
    serviceAccountId: string,
    widgets: CloudWatchWidget[],
    layoutType: 'manual' | 'automatic',
    periodOverride?: 'inherit' | 'auto'
}

export const dashboardConfig = (partition: string, region: string, serviceAccountId: string) => {
    return {
      displayMode: 'static',
      widgetMarginX: 10,
      widgetMarginY: 10,
      staticWidgets: true,
      style: 'polaris',
      internal: {
        accountId: serviceAccountId,
      },
      region: region
    };
};

const CloudWatchDashboard : React.FC<Props> = ({broker, layoutType, widgetsPerRow, serviceAccountId, widgets, periodOverride = 'auto'}) => {
    const dashboard = useRef<Dashboard | undefined>(undefined);
    const dashboardContainer = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    let arn = Arn.parse(broker.brokerInfo.arn);
    let partition = arn.partition;
    let regionName = arn.region;

    useEffect(() => {
        setLoading(true);
        dashboard.current = new Dashboard(
            dashboardContainer.current,
            'Dashboard',
            regionName,
            dashboardConfig(partition, regionName, serviceAccountId),
            {},
            "",
            partition
        );
        dashboard.current.on('ready', () => displayDashboard());

    }, []);

    useEffect(() => {
        if (dashboard.current === undefined) {
            return;
        }
    }, [dashboard.current])

    useEffect(() => {
        if (dashboard.current === undefined) {
            return;
        }
        dashboard.current.update(
            { widgets: widgets, start: "-P3D", periodOverride: periodOverride },
            layoutType === 'automatic' ?
            {
                widgetsPerRow: widgetsPerRow,
                widgetHeightInUnits: 8,
            } : undefined
        );
    }, [widgets]);

    function displayDashboard() {
        if (dashboard.current === undefined) {
            return;
        }
        const theme = localStorage.getItem("THEME") === "Dark" ? Mode.Dark :  Mode.Light;
        const iframeElement : any = document.getElementById('embedded-cloudwatch-dashboards');
        if (iframeElement) {
            iframeElement.contentWindow.postMessage({
                type: 'colorThemeChanged',
                payload: {
                    colorTheme: theme === Mode.Dark ? "dark" : "light",
                },
            }, iframeElement.src);
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            {
                loading &&
                <div className="spinner">
                    <Spinner />
                </div>
            }
            <div ref={dashboardContainer} />
        </React.Fragment>
    );
}

export default CloudWatchDashboard;