import React from 'react';
import { LogEntry } from '../../../types';
import { Flashbar, Table } from '@amzn/awsui-components-react';

type Props = {
    logs: LogEntry[],
    loadingData: boolean,
    error: string
}

const LogDetailsTable : React.FC<Props> = ({logs, loadingData, error}) => {

    let columnDefinitions = [
        {
            id: "timestamp",
            field: "timestamp",
            header: "Timestamp",
            minWidth: 150,
            cell: (item: any)  => <p>{item.timestamp}</p>
        },
        {
            id: "log",
            field: "log",
            header: "Log",
            cell: (item: any)  => <p>{item.log}</p>
        }
    ]
    
    return (
        <>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
            {!error &&
                <Table
                    items={logs}
                    empty="No Data found"
                    columnDefinitions={columnDefinitions}
                    // filteringPlaceholder='Search logs'
                    loading={loadingData}
                />
            }
        </>
    )
}

export default LogDetailsTable;
