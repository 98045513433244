import React, { useEffect, useState } from 'react';
import { getFailoverHistory } from '../../../api/api';
import { Broker, QualifiedFailoverEvent } from '../../../types';
import { Table } from '@amzn/awsui-components-react';


type Props = {
    broker: Broker,
}

const FailoverHistory : React.FC<Props> = ({broker}) => {

    const [failoverHistory, setFailoverHistory] = useState<QualifiedFailoverEvent[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    useEffect(() => {
        getFailoverHistory(broker.id, broker.snapshotId).then(response => {
            setFailoverHistory(response.failoverHistory);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingData(false);
        })
    }, []);

    let columnDefinitions = [
        {
            id: "arcUpdateTime",
            header: "ARC Update Time",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.arcUpdateTime,
            sortingField: 'arcUpdateTime'
        },
        {
            id: "initiationTime",
            header: "Initiation Time",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.initiationTime,
            sortingField: 'initiationTime'
        },
        {
            id: "completionTime",
            header: "Completion Time",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.completionTime,
            sortingField: 'completionTime'
        },
        {
            id: "mode",
            header: "Mode",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.mode,
            sortingField: 'mode'
        },
        {
            id: "roleAfterFailover",
            header: "Role after Failover",
            cell: (item: QualifiedFailoverEvent) => item.roleAfterFailover,
        }
    ]

    let sortableColumns = [
        {
            id: "arcUpdateTime",
            field: "arcUpdateTime"
        },
        {
            id: "initiationTime",
            field: "initiationTime"
        },
        {
            id: "completionTime",
            field: "completionTime"
        },
        {
            id: "mode",
            field: "mode"
        }
    ]

    return (
        <div className="infoTable">
            <Table
                items={failoverHistory}
                empty="No data found"
                columnDefinitions={columnDefinitions}
                loading={loadingData}
                sortingColumn={{
                    sortingField: 'initiationTime'
                }}
                sortingDescending={true}
            />
        </div>
    );

}

export default FailoverHistory;
