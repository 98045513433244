import React, { useEffect, useState } from 'react';
import { Alert, Button, Flashbar, Header, Link, StatusIndicator, Table, TableProps, TextFilter } from '@amzn/awsui-components-react/polaris';
import { Broker, Workflow, WorkflowStatus, WorkflowType } from '../../../types';
import { createIsengardLink } from '../../../utils';
import { isoFormat } from 'd3-time-format';
import useConfig from '../../../hooks/useConfig';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { getBrokerWorkflows } from '../../../api/api';

type Props = {
    broker: Broker,
}  

const Workflows : React.FC<Props> = ({broker}) =>  {


    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const config = useConfig();
    const [refreshCnt, setRefreshCnt] = useState<number>(0);

    const {
        items,
        collectionProps,
        filterProps
      } = useCollection(workflows ?? [], {
        filtering: {},
        sorting: {
          defaultState: {
            sortingColumn: {
                sortingField: 'startTime',
            },
            isDescending: true,
          },
        },
      });

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getBrokerWorkflows(broker.id, broker.snapshotId).then(response => {
            setWorkflows(response.workflows);
        }).catch(error => {
            setError("Could not load workflows.");
            console.error(error);
        }).finally(() => {
            setLoadingData(false)
        })
    }, [refreshCnt]);

    if (config === undefined) {
        return <></>
    }

    function createStepFunctionsIsengardLink(workflow: Workflow) {
        if (config === undefined) {
            return "";
        }
        const accountId = workflow.workflowType === WorkflowType.BROKER_CELL_WORKFLOW
             ? broker.brokerInfo.cellAccountId
             : broker.brokerInfo.controlPlaneAccountId;
        const destination = `states/home?region=${broker.regionName}#/executions/details/${workflow.executionArn}`;
        return createIsengardLink(config.isengardEndpoint, accountId, 'ReadOnly', destination);
    }

    function renderWorkflowStatus(status: WorkflowStatus) {
        if (status === 'FAILED') {
            return <StatusIndicator type='error'>FAILED</StatusIndicator>;
        } else if (status === 'SUCCEEDED') {
            return <StatusIndicator type='success'>SUCCEEDED</StatusIndicator>;
        } else if (status === 'RUNNING') {
            return <StatusIndicator type='in-progress'>RUNNING</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{status}</StatusIndicator>;
        }
    }


    let columnDefinitions: TableProps.ColumnDefinition<Workflow>[] = [
        {
            id: "workflowName",
            header: "Workflow",
            cell: (item: Workflow) => <Link href={createStepFunctionsIsengardLink(item)} target="_blank">{item.workflowName}</Link>,
            sortingField: 'workflowName',
            maxWidth: 400
        },
        {
            id: "type",
            header: "Type",
            cell: (item: Workflow) => item.workflowType === WorkflowType.BROKER_CELL_WORKFLOW ? "Cell": "Control Plane"
        },
        {
            id: "maintenance",
            header: "Maintenance",
            cell: (item: Workflow) => item.executionArn.includes("Workflow:mw-") ? "true" : "false",
            maxWidth: 110
        },
        {
            id: "status",
            header: "Status",
            cell: (item: Workflow) => renderWorkflowStatus(item.status),
            sortingField: 'status',
        },
        {
            id: "startTime",
            header: "Start Time",
            cell: (item: Workflow) => item.startTime ? isoFormat(new Date(item.startTime * 1000)) : "",
            sortingField: 'startTime',
        },
        {
            id: "endTime",
            header: "End Time",
            cell: (item: Workflow) => item.endTime ? isoFormat(new Date(item.endTime * 1000)) : "",
            sortingField: 'endTime',
        }
    ]

    return (
        <div className="infoTable" style={{display: 'flex', flexDirection: 'column', gap: 8}}>
             {error && <Flashbar items={
                                [{
                                    "type": "error",
                                    "dismissible": false,
                                    "content": error
                                }]
                            }></Flashbar>}
            <Alert type='info'>This feature is not ready yet, use old console to see broker workflows</Alert>
            <Table
                {...collectionProps}
                items={items}
                filter={
                    <TextFilter
                        {...filterProps}
                        filteringPlaceholder="Search Workflow"
                    />
                }
                columnDefinitions={columnDefinitions}
                loading={loadingData}
                header={
                    <Header
                        actions={
                            <>
                                <Button disabled={loadingData} iconName="refresh" onClick={() => {
                                    setRefreshCnt(refreshCnt + 1);
                                }} />
                            </>
                        } 
                    >Broker Workflows</Header>
                }
                wrapLines={true}
                empty="No Workflows found"
            />
        </div>
    )

}

export default Workflows;
