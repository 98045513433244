import React from 'react';
import { Broker } from '../../types';
import { TextContent, Badge } from '@amzn/awsui-components-react/polaris';
import CopyToClipboardButton from './copyToClipboardButton';
import CopyMarkDownButton from './CopyMarkDownButton';
import BrokerSummaryTable from './BrokerSummaryTable';

type Props = {
    broker: Broker
}

const BrokerHeader : React.FC<Props> = ({broker}) => {
    return (
        <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 16}}>
            <div style={{display: 'flex', gap: 8, flexDirection: 'row', alignItems: 'center',  marginBottom: 8}}>
                <TextContent>
                    <h2>{broker.id}</h2>
                </TextContent>
                <CopyToClipboardButton
                    text={broker.id}
                    textDescription={broker.id}
                />
                {broker.summary.ourBroker && <Badge color="blue">Our Broker</Badge>}
                <span style={{flexGrow: 1}}></span>
                <CopyMarkDownButton
                    brokerId={broker.id}
                    snapshotId={broker.snapshotId}
                />
            </div>
            <BrokerSummaryTable
                broker={broker}
            />
        </div>
    )
}

export default BrokerHeader;