import { Broker, BrokerDeploymentMode, Ec2Instance } from '../../../../types';
import { widget } from '../utils';

export const CELL_WIDTH = 12;
export const CELL_HEIGHT = 8;

export function activeNodeCountAndContainerAgeWidget(broker: Broker, brokerInstances: Ec2Instance[], x: number, y: number, width: number, height: number) {

    if (broker.summary.deploymentMode === BrokerDeploymentMode.SINGLE_INSTANCE) {
        if (brokerInstances.length > 0) {
            return widget(
                "ActiveNodeCount, RabbitMqContainerAge",
                broker.regionName,
                [   
                    activeNodeCountMetric(broker, brokerInstances[0].instanceId),
                    rabbitMqContainerAgeMetric(broker, brokerInstances[0].instanceId),
                ],
                x,
                y,
                width,
                height
            )
        } else {
            return emptyWidget("ActiveNodeCount, RabbitMqContainerAge", broker.regionName, 0, 0, 24, 8);
        }
    } else if (broker.summary.deploymentMode === BrokerDeploymentMode.CLUSTER_MULTI_AZ) {

        let allMetrics = [];
        for (let i = 0; i < brokerInstances.length; i++) {
            allMetrics.push(activeNodeCountPerInstanceMetric(broker, brokerInstances[i].instanceId, "m" + i))
        }

        allMetrics.push(sumActiveNodeCountMetric(broker));

        for (let i = 0; i < brokerInstances.length; i++) {
            allMetrics.push(rabbitMqContainerAgeMetric(broker, brokerInstances[i].instanceId))
        }

        return widget(
            "ActiveNodeCount+ContainerAge",
            broker.regionName,
            allMetrics,
            x,
            y,
            width,
            height
        )

    } else {
        throw new Error("Unsupported deployment mode: " + broker.summary.deploymentMode);
    }
}

export function sumActiveNodeCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ActiveNodeCount",
        "BrokerId",
        broker.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right",
            period: 60
        }
    ];
}

export function activeNodeCountMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "ActiveNodeCount",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ];
}

export function rabbitMqContainerAgeMetric(broker: Broker, instanceId: string) {
    return [
        "AmazonMqRabbitService",
        "RabbitMqContainerAge",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function activeNodeCountPerInstanceMetric(broker: Broker, instanceId: string, id: string) {
    return [
        "AmazonMqRabbitService",
        "ActiveNodeCount",
        "BrokerId",
        broker.id,
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
            visible: false,
            id: id
        }
    ];
}

export function emptyWidget(title: string, regionName: string, 
    x: number, y: number, width: number, height: number) {
    return widget(
        title,
        regionName,
        [],
        x,
        y,
        width,
        height
    )
}