import React from 'react';
import { Modal, Table } from '@amzn/awsui-components-react/polaris';
import './MemoryBreakdownModal.css';
import MemoryBar from './MemoryBar';
import { SECTIONS } from './const';
import CopyToClipboardButton from '../copyToClipboardButton';
import { BrokerInstanceMemoryBreakdown, Ec2Instance } from '../../../types';

type Props = {
    open: boolean,
    instance: Ec2Instance,
    instanceMemoryBreakdown: BrokerInstanceMemoryBreakdown,
    onClose: () => void
}

type TableRow = {
    fieldName: string;
    fullName: string;
    valueInGb: number;
    percentage: number;
} 

const MemoryBreakdownModal : React.FC<Props> = ({open, instance, instanceMemoryBreakdown, onClose}) => {

    let valueAsText = "";
    let items: TableRow[] = [];
    let breakdown : any = instanceMemoryBreakdown.memoryBreakdown;
    let totalBytesUsed : number = 0.0;
    for (let key in instanceMemoryBreakdown.memoryBreakdown) {
        totalBytesUsed += breakdown[key].bytes;
    }

    for (let key in instanceMemoryBreakdown.memoryBreakdown) {
        let valueInBytes : number = breakdown[key].bytes;
        let valueInGb : number = valueInBytes / Math.pow(10, 9);
        items.push({
            fieldName: key,
            valueInGb: valueInGb,
            percentage: breakdown[key].percentage,
            fullName: SECTIONS[key] ? SECTIONS[key][1] : key
        })
        valueAsText += `${key}: ${valueInGb.toFixed(5)} gb (${(breakdown[key].percentage).toFixed(2)}%)\n`
    }

    return (
        <Modal 
            size="large"
            header={`${instance.instanceId} Memory Breakdown`}
            visible={open}
            onDismiss={() => {
                onClose();
            }}
        >
            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <MemoryBar data={instanceMemoryBreakdown} />
                    <div style={{flexGrow: 1}}></div>
                    <CopyToClipboardButton text={valueAsText} />
                </div>
                <div style={{maxHeight: 500, overflow: 'scroll'}}>
                <Table
                    items={items}
                    wrapLines={true}
                    columnDefinitions={[
                        {
                            id: "Color",
                            header: "",
                            width: 15,
                            cell: (item: TableRow) => {
                                if (SECTIONS[item.fieldName]) {
                                    return  <div style={{width: 15, height: 15}} className={`memory-section memory_${SECTIONS[item.fieldName][0]}`}></div>
                                } else {
                                    return <></>
                                }
                            }
                        },
                        {
                            id: "Name",
                            header: "Name",
                            cell: (item: TableRow) => item.fullName
                        },
                        {
                            id: "field",
                            header: "Field",
                            cell: (item: TableRow) => item.fieldName
                        },
                        {
                            id: "valueInGb",
                            header: "Memory Used (gb)",
                            cell: (item: TableRow) => item.valueInGb.toFixed(5)
                        },
                        {
                            id: "percentage",
                            header: "%",
                            cell: (item: TableRow) => `${(item.percentage).toFixed(2)}%`
                        }
                    ]}
                />
                </div>
            </div>
        </Modal>
    )
}

export default MemoryBreakdownModal;



                    /* <TableSorting
                        sortableColumns={[{id: 'valueInGb', field: 'valueInGb'}, {id: 'percentage', field: 'percentage'}]}
                        sortingColumn='valueInGb'
                        sortingDescending={true} /> */