import { Container, Flashbar, Header, Spinner } from '@amzn/awsui-components-react/polaris';
import React, { useEffect, useState } from 'react';
import JSONPretty from 'react-json-pretty';
import { getRabbitBrokerEnvironment } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { Broker, BrokerEnvironment } from '../../../types';

type Props = {
    broker: Broker
}

const BrokerEnvironmentPage : React.FC<Props> = ({broker}) => {

    const [brokerEnvironment, setBrokerEnvironment] = useState<BrokerEnvironment | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitBrokerEnvironment(broker.id, broker.snapshotId).then(response => {
            setBrokerEnvironment(response);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker environment");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id])

    return (
        <div>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
                <Container header={
                    <Header variant='h2'>
                        Broker Environment
                    </Header>
                }>
                     {loading && <Spinner /> }
                     {!loading && !error && brokerEnvironment &&
                        <>
                            <CopyToClipboardButton text={JSON.stringify(brokerEnvironment)} />
                            <JSONPretty data={brokerEnvironment}></JSONPretty>
                        </>
                     }
                </Container>
        </div>
    );
}

export default BrokerEnvironmentPage;